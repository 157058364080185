import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import { PageSeo } from "../components/PageSeo"
import "./styles/company.scss"

const AboutTemplate = ({ data }) => {
  const { markdownRemark: markup } = data
  const { name, blurb, credo } = markup.frontmatter
  return (
    <Layout>
      <section className="AboutTemplate">
        <h2 className="text-center py-3 py-lg-5">{name}</h2>
        <AboutUsComponent blurb={blurb} />
        <CredoComponent credo={credo} />
      </section>
    </Layout>
  )
}
AboutTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

const AboutUsComponent = ({ blurb }) => {
  return (
    <div className="container">
      {blurb.length &&
        blurb.map((item, index) => (
          <div key={`aboutUs_${index}`}>
            <div
              className={`row justify-content-md-center align-items-center animate__animated animate__fadeInRight ${
                index % 2 > 0 ? "flex-row-reverse" : ""
              }`}
            >
              <div className="col-12 col-lg-6 content animate__animated animate__fadeInLeft">
                <h6 className="text-uppercase text-muted subheading">
                  {item.heading}
                </h6>
                <ReactMarkdown children={item.content}></ReactMarkdown>
              </div>
              <div className="col-12 col-lg-6  mb-3 animate__animated animate__fadeInRight">
                <GatsbyImage
                  image={item.image.childImageSharp.gatsbyImageData}
                  alt={item.heading}
                  className=""
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}
AboutUsComponent.propTypes = {
  blurb: PropTypes.shape({
    heading: PropTypes.string,
    content: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
}

const CredoComponent = ({ credo }) => {
  return (
    <div className="bg-light py-3 py-lg-5 border-bottom">
      <div className="container">
        <h4 className="pb-3  text-center">{credo.title}</h4>
        <div className="row justify-content-md-center">
          {credo.blurb.length &&
            credo.blurb.map((item, index) => (
              <div key={`credo_${index}`} className="col-12 col-lg-4 mb-3">
                <div className="card h-100 text-center ">
                  <div className="card-header bg-transparent border-0">
                    <div className="card-img mt-3">
                      <GatsbyImage
                        image={item.image.childImageSharp.gatsbyImageData}
                        alt={item.heading}
                      />
                    </div>
                  </div>
                  <div className="card-body">
                    <h6 className="text-uppercase text-muted subheading">
                      {item.heading}
                    </h6>
                    <ReactMarkdown children={item.content}></ReactMarkdown>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
CredoComponent.propTypes = {
  credo: PropTypes.shape({
    title: PropTypes.string,
    blurb: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        content: PropTypes.string,
        image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      })
    ),
  }),
}

export const AboutTemplateQuery = graphql`
  query AboutUsQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        blurb {
          heading
          content
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        credo {
          title
          blurb {
            heading
            content
            image {
              childImageSharp {
                gatsbyImageData(width: 50, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`
export default AboutTemplate
export const Head = () => (
  <PageSeo
    title="About Credence | Ranked #1 Investment Management Software Solution Provider"
    description="Credence Analytics is Global Solution Provider Of Mission-Critical Software And IT Services."
    keywords="Credence Analytics is global solution provider of mission-critical software solution and IT services which help automate processes in Banks, Asset Management Companies, Insurance Companies, Non-Banking Finance Companies and Corporate Finance Departments in the areas of Treasury, Investment Portfolio Management, Fund Administration, Investor Servicing and Risk Management."
  />
)
